/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,500;1,100;1,300;1,500&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap'); */
  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Raleway:ital,wght@0,100;0,300;0,500;1,100;1,300;1,500&family=Righteous&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap');


  :root {
  --glow-color: #019e38;
}

* {
  font-family: 'Raleway', sans-serif;
  /* width: 100% !important; */
  margin: 0px;
  
}

body {
  margin: -20px;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow: auto;
  height: 100%;
  /* overflow: hidden; */
}
html {
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font {
  font-family: 'Raleway', sans-serif;
  text-align: center;
}
.exitFont {
  font-family: 'Bebas Neue', sans-serif;
  /* font-size: 2em; */
  text-align: center;
}

.titleFont {
  font-family: 'Righteous', cursive;
  font-size: 4em;
  text-align: center;
}
.italic {
  font-style: italic;
}

.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.matrixButton {
  background-color: black;
  color: 	#1DB954;
}

.homepage {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  justify-content: center;
  /* height: 100vh; */
  /* font-size: 24px; */
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.podcasts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.logo {
  width: 130px;
  height: 130px;
  margin: 10px 25px 10px 25px;
  /* border: solid rgb(15, 15, 15) 1px; */
  border-radius: 100px;
  /* padding: 10px; */
  color: rgb(32, 32, 32);
  cursor: pointer;
  /* padding: 0.35em 1em; */
  border: 0.15em solid rgb(32, 32, 32);
  background: none;
  perspective: 2em;
  animation: border 5s linear infinite;
}

.logo:hover {
  /* z-index: -1; */
  /* box-shadow: 0 0 .2em  var(--glow-color); */
  transition: opacity 100ms linear;
  text-shadow: none;
  /* border: solid #019e38 1px; */
  width: 136px;
  height: 136px;
  margin: 7px 22px 7px 22px;

}


.logo:hover:before {
  filter: blur(1.5em);
}


.divider {
  width: 200px;
  height: 200px;
  margin: 10px;

}

.contact {
  font-size: 18px;
}

.title {
  text-align: center;
  /* padding: 20px; */
  /* font-variant: small-caps; */

}

.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navButton {
  transition: all .2s ease-in-out;
  margin-left: 10px;
  padding: 15px -10px 10px 0px;
  text-decoration: none;
}

.navButton:hover {
  color:#D28068 !important;  
}

.subtitle {
  text-align: center;
  font-size: 14px;
}

.paragraph {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  word-wrap:break-word;
}

.pageSection {
  padding: 30px 10% 5% 10%;
}

.pageSection2 {
  padding-top: 50px;
  
}

.pageSection3 {
  padding: 30px 00px 0px 00px;
  text-align: center;
  border-top: solid gray 1px;
  margin-top: 20px;
  /* width: 500px; */
}

.pageSection4 {
  padding: 0px 00px 00px 00px;
}

.nostr {
  width: 200px;
  padding: 10px;
}
.nostr:hover {
  width: 205px;
  padding: 10px;
}

.nostrBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap:break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  padding: 20px;
}

.nostrText {
  display: flex;
  flex-wrap: wrap;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.logo-main {
  width: 400px;
}

.logo-appearances {
  width: 300px;
}

.paddingTop {
  padding-top: 0px;
}

#widget2 {
  width: 120vw;
  Height: 120vh;
}

.matrix-video {
  
}


.whiteBox {
  /* border: solid green 1px; */
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  background-color: #2b2b2b;
  margin-top: 50px;
  border-radius: 10px;
  text-align: center;
  overflow-wrap:break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.greenBox {
    margin: auto;
    border: solid #019e38 1px;
    /* width: 355px; */
    padding: 10px;
    border-radius: 3px;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 20px; */
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  z-index: 1;
  
}



.overlay {
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  /* background: rgba(55, 104, 129, 0.8); */
  color: #f1f1f1;
  /* transition: .5s ease; */
  font-size: 20px;
  text-align: center;
  transition: all 3s ease-in-out;

  /* padding: 0px; */
  /* width: 100%; */
  /* height: 200px; */
}


.devButton {
  /* display: flex; */
  flex-shrink: 1;
  border: 1px solid white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #DBC8B6;
  cursor: pointer;
  display: inline-block;
  font-family: 'Urbanist', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  background: green;
  font-size: 20px;
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
}

.devButton:hover,
.devButton:active {
  background-color: #DBC8B6;
  background-position: 0 0;
  color: #366981;
}

.devButton:active {
  opacity: .5;
}

.footer {
  /* background-color: rgba(14, 51, 80, 0.9); */
  color: white;
  bottom: 0;
  width: 100vw;
  background-image: url('https://res.cloudinary.com/dkkwdvec2/image/upload/v1690921118/pattern_vvlu9t.jpg');
  text-align: center;
  margin-bottom: -60px;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index:100;
}

.grayOverlay {
  background: rgba(43, 43, 43, 0.75);
  padding: 80px 80px;
  /* margin-bottom: -60px; */
  width: 100vw;
 }






.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.1em .6em .01em .6em;
  border: 0.1em solid var(--glow-color);
  border-radius: 0.15em;
  background: none;
  /* perspective: 2em; */
  font-family: "Raleway", sans-serif;
  font-size: 4em;
  font-weight: 550;
  /* margin: auto; */
  letter-spacing: 2px;

  /* -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color); */
  animation: border 5s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.4em;
  margin-left: -0.3em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0 hsl(0 0% 100% / 0), 0 0 0sem var(--glow-color);
  animation: faulty-flicker 7s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 5s linear infinite;
}
/* 
.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: var(--glow-color);
  pointer-events: none;
} */

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  /* box-shadow: 0 0 2em 0.2em var(--glow-color); */
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.6;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  83% {
    opacity: 0.8;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 700px) {
  .glowing-btn{
    font-size: 3em;
  }
  .logo-main{
    width: 300px;
  }
  .titleFont  {
    font-size: 3em;
  }
}

.appearances-btn {
  background-color: #2b2b2b; /* Dark background */
  color: #ffffff; /* White text */

  padding: 15px 25px; /* Padding around the button */
  font-size: 22px; /* Font size */
  font-weight: 600; /* Slightly bold */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove underline */
  transition: all 0.3s ease; /* Smooth hover effect */
}

.appearances-btn:hover {
  background-color: #019e38; /* Invert background on hover */
  color: #fff; /* Invert text color */
}

.button-a {
margin: 25px;
}

.appearances-btn:hover {
  /* z-index: -1; */
  /* box-shadow: 0 0 .1em  var(--glow-color); */
  transition: opacity 100ms linear;
  text-shadow: none;
  border: solid #019e38 1px;

}


.thumbnail {
  width: 300px;
  margin: 10px 25px 10px 25px;
  /* border: solid rgb(15, 15, 15) 1px; */
  border-radius: 10px;
  /* padding: 10px; */
  color: rgb(32, 32, 32);
  cursor: pointer;
  /* padding: 0.35em 1em; */
  border: 0.15em solid rgb(32, 32, 32);
  background: none;
  perspective: 2em;
  animation: border 5s linear infinite;
}
